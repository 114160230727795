import React from "react";
import rmLogo from "../Materix Logo.png";

function RmPrivacyPolicy() {
  return (
    <div className="privacy-policy-container">
      <div>
        <a>
          <img
            src={rmLogo}
            className="logo"
            style={{
              height: 80,
              width: 80,
              objectFit: "contain",
              cursor: "pointer",
            }}
            alt="logo"
          />
        </a>
      </div>

      <h1>Company Policy for Raw Material Marketplace</h1>

      <h2>1. Introduction</h2>
      <p>
        Materix ("Company," "We," "Us," "Our") operates a raw material marketplace that connects buyers and sellers of
        construction raw materials. This policy outlines the rights, obligations, and terms governing the use of our
        platform.
      </p>
      <p>
        By using our platform, users agree to abide by this policy and comply with all applicable Indian laws, including
        but not limited to:
      </p>
      <ul>
        <li>The Information Technology Act, 2000 and IT (Intermediary Guidelines) Rules, 2021</li>
        <li>The Consumer Protection Act, 2019</li>
        <li>The Goods and Services Tax (GST) Act</li>
        <li>The Indian Contract Act, 1872</li>
        <li>The Payment and Settlement Systems Act, 2007</li>
        <li>The Competition Act, 2002</li>
        <li>Any other applicable laws and regulations</li>
      </ul>

      <h2>2. Scope of Services</h2>
      <ul>
        <li>Provide an online marketplace for listing and discovering raw materials.</li>
        <li>Charge a platform fee and subscription fee for using our services.</li>
        <li>Facilitate material transaction payments between buyers and sellers.</li>
      </ul>
      <p>We do not directly sell raw materials but only act as an intermediary between buyers and sellers.</p>

      <h2>3. User Registration and Responsibilities</h2>
      <h3>3.1 Registration Requirements</h3>
      <ul>
        <li>
          Users must register by providing valid business details, GST number (if applicable), PAN, and KYC documents.
        </li>
        <li>The user must be at least 18 years old and legally competent to enter contracts.</li>
      </ul>

      <h3>3.2 Accuracy of Information</h3>
      <ul>
        <li>
          Users must provide accurate and complete information. Any misrepresentation may lead to account suspension or
          legal action.
        </li>
        <li>
          Sellers must ensure that product listings comply with Indian laws, including Bureau of Indian Standards (BIS)
          and other quality regulations.
        </li>
      </ul>

      <h3>3.3 Prohibited Activities</h3>
      <ul>
        <li>Listing counterfeit, illegal, or restricted materials.</li>
        <li>Engaging in fraudulent, misleading, or deceptive practices.</li>
        <li>Attempting to bypass platform fees or engage in off-platform transactions.</li>
      </ul>

      <h2>4. Fees, Payments, and Transactions</h2>
      <h3>4.1 Platform Fee and Subscription Fee</h3>
      <p>
        We charge a platform fee for each transaction processed through our platform. We also offer subscription-based
        plans for additional services.
      </p>

      <h3>4.2 Payment Processing</h3>
      <p>
        Payments for material transactions are processed through our integrated payment gateway in compliance with the
        Payment and Settlement Systems Act, 2007.
      </p>

      <h3>4.3 Taxes and GST Compliance</h3>
      <ul>
        <li>Users are responsible for paying applicable GST and other taxes.</li>
        <li>Sellers must issue GST invoices for sales made through the platform.</li>
        <li>The company will deduct TDS/TCS (if applicable) under Income Tax Act regulations.</li>
      </ul>

      <h2>5. Order Fulfillment and Dispute Resolution</h2>
      <h3>5.1 Delivery and Logistics</h3>
      <p>
        Sellers are responsible for timely order fulfillment. If we offer logistics support, it will be governed by
        separate service terms.
      </p>

      <h3>5.2 Dispute Resolution</h3>
      <p>
        Any disputes between buyers and sellers must be resolved as per our dispute resolution process. If disputes
        remain unresolved, users may seek legal remedies in accordance with Indian law.
      </p>

      <h2>6. Cancellations, Refunds, and Returns</h2>
      <p>
        Cancellation and refund policies depend on individual seller terms unless otherwise specified by the platform.
      </p>

      <h2>7. Data Privacy and Security</h2>
      <h3>7.1 Data Collection and Use</h3>
      <p>
        We collect user data as per the Information Technology (Reasonable Security Practices and Procedures and
        Sensitive Personal Data or Information) Rules, 2011.
      </p>

      <h3>7.2 Data Sharing and Protection</h3>
      <p>
        We do not sell user data to third parties. We implement industry-standard encryption to protect financial
        transactions. Users can request data deletion as per applicable laws.
      </p>

      <h2>8. Compliance with Indian Laws</h2>
      <p>We comply with all applicable Indian laws, including IT, anti-money laundering, and competition laws.</p>

      <h2>9. Intellectual Property Rights</h2>
      <p>
        All content, trademarks, and proprietary information on the platform belong to Materix or its licensors. Users
        are prohibited from copying, modifying, or misusing any platform content.
      </p>

      <h2>10. Liability and Indemnification</h2>
      <p>
        We act solely as an intermediary and are not responsible for the quality, safety, or legality of materials sold.
      </p>

      <h2>11. Termination of Accounts</h2>
      <p>
        We reserve the right to suspend or terminate user accounts in case of policy violations, fraudulent activities,
        non-payment of fees, or legal non-compliance.
      </p>

      <h2>12. Governing Law and Jurisdiction</h2>
      <p>
        This policy is governed by the laws of India. Any disputes arising out of this policy shall be subject to the
        exclusive jurisdiction of the courts in  D no 354A,1st floor, MRP Complex, Thider Nagar, Cauvery Nagar,
        Kulathur(Taluk), Pudukkottai, Tamil Nadu, India..
      </p>

      <h2>13. Amendments to the Policy</h2>
      <p>
        The company reserves the right to modify this policy at any time. Users will be notified of significant changes.
      </p>
    </div>
  );
}

export default RmPrivacyPolicy;
